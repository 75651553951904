'use client'

import { useEffect, useRef } from 'react'

function useWindowScrollListener({
  callback,
  isDisabled,
}: {
  callback: (event: Event) => void
  isDisabled: boolean
}) {
  const latestCallbackRef = useRef(callback)

  useEffect(() => {
    latestCallbackRef.current = callback
  })

  useEffect(() => {
    if (isDisabled) {
      return
    }

    document.addEventListener('scroll', latestCallbackRef.current)
    return () => {
      document.removeEventListener('scroll', latestCallbackRef.current)
    }
  }, [isDisabled])
}

export { useWindowScrollListener }
