import { theme } from '@pienso/styles'

function PinIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88911 6.22266L2.50051 1.55599L13.2777 1.55599L7.88911 6.22266Z"
        fill={theme.colors.green}
      />
      <path
        d="M7.88845 6.22266L14.0469 15.2227L1.73005 15.2227L7.88845 6.22266Z"
        fill={theme.colors.green}
      />
      <rect
        x="5.66602"
        y="3.11133"
        width="4.44444"
        height="8"
        fill={theme.colors.green}
      />
      <rect
        x="7.44336"
        y="15.1108"
        width="0.888889"
        height="4.88889"
        fill={theme.colors.green}
      />
    </svg>
  )
}

export { PinIcon }
