import { BlogArticleCardItem } from '@pienso/blog'
import { Link } from '@pienso/components'
import { HeaderTabGroupResources } from '@pienso/models'
import * as s from './TabGroupResources.css'

export function TabGroupResources({
  faqSection,
  newsSection,
}: HeaderTabGroupResources) {
  return (
    <div className={s.root}>
      {newsSection !== undefined && (
        <div>
          <p className={s.newsSectionTitle}>{newsSection.title}</p>
          <ul className={s.news}>
            {newsSection.news?.map((n) => {
              return <BlogArticleCardItem key={n._key} article={n.item} />
            })}
          </ul>
          <Link href="/blog" appearance="secondaryBlue">
            Go to Blog
          </Link>
        </div>
      )}
      {faqSection !== undefined && (
        <div className={s.faqSection}>
          <p className={s.faqSectionTitle}>{faqSection.title}</p>
          <ul className={s.questions}>
            {faqSection.links?.map(({ _key, link }) => {
              return (
                <li key={_key}>
                  <Link
                    appearance="secondaryBlue"
                    className={s.question}
                    {...link?.payload}
                  >
                    {link?.payload?.label}
                  </Link>
                </li>
              )
            })}
          </ul>
          <Link href="/faq" appearance="secondaryBlue" className={s.faqLink}>
            Read all questions
          </Link>
        </div>
      )}
    </div>
  )
}
