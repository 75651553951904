export const sizes = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  28: '28px',
  30: '30px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  52: '52px',
  56: '56px',
  60: '60px',
  64: '64px',
  72: '72px',
  80: '80px',
  96: '96px',
  100: '100px',
  120: '120px',
  124: '124px',
  128: '128px',
  140: '140px',
  144: '144px',
  152: '152px',
  160: '160px',
  168: '168px',
  180: '180px',
  192: '192px',
  240: '240px',
  288: '288px',
  290: '290px',
  378: '378px',
  416: '416px',
  contentMaxWidth: '2000px',
  mobileHeaderHeight: '128px',
  mobileStickyHeaderHeight: '60px',
  mobileHeaderNavigationItemHeight: '54px',
  desktopHeaderHeight: '343px',
  desktopHeaderNavigationHeight: '100px',
  desktopStickyHeaderHeight: '61px',
  buttonHeightS: '28px',
  buttonHeightM: '44px',
  containerPadding: '24px',
  sectionVerticalPaddingTop: '24px',
  sectionVerticalPaddingBottom: '36px',
} as const
