import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fheader%2Fsrc%2Flib%2Fcomponents%2Fmobile%2FMobileHeaderNavigationItem.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62TYWvjMAyGv%2FdXiMFB98HF2dZ15zHY%2FxjjUGI19ZZYxlbW9I7%2B98NJoWnXHcdx3%2BK80qPXkryIqVvqBw2%2FZgAVNxwNfGCcK%2FVj5du3VQzXjzOAkqOlqISDgSL0kLhx9nPgfrYYecXA44CVk50BvbhdTtTbQbUuhQZ3BsqGq%2FdcpXVebcjVGzkzcVMOLgJa63x9JhY4rXx3yl431OfUty6JW%2B9UxV7Ii4EUsCJVkmyJfI7AxtVeOaE2GajIC8X8e%2BusbAwUWn%2F7XxaX%2F2bxeMUXjA4V9QG9Jft0JbGjq1c4yPcD%2FjCxkkW4%2FbuhrYZEieiTE8fejN9rji3oxU2atOPz7QAutuVsOH92PjGQixqILCg0Lx60pXoK%2Bn7awjo6my38VM5b6s889OMKY%2FVeR%2B68VZf2fDPF44DPVCXUhgaFVORtMlCs4ySu%2FDJOj3HPLVmHMM9rc9wkHfrrIfOk3OS9FI%2FDObDLa6jog7wkA9gJZ2V%2FTC0PqV96PeHqy1zPnkbu%2FjfRxmM1EAQAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var groupItemIcon = 'rsu5087';
export var navigationItem = _7a468({defaultClassName:'rsu5080',variantClassNames:{disabled:{true:'rsu5081',false:'rsu5082'}},defaultVariants:{disabled:false},compoundVariants:[]});
export var navigationItemButton = 'rsu5084';
export var navigationItemButtonContent = 'rsu5085';
export var navigationItemButtonLabel = 'rsu5086';
export var navigationItemLink = 'rsu5083';
export var tabGroupContent = _7a468({defaultClassName:'rsu5089',variantClassNames:{isOpen:{true:'rsu508a',false:'rsu508b'}},defaultVariants:{},compoundVariants:[]});
export var tabGroupContentTopOffset = 'var(--rsu5088)';