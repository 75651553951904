import { Link, Media, SingleBlockPortableText } from '@pienso/components'
import { HeaderTabGroupPlatform, isRenderableMediaField } from '@pienso/models'
import * as s from './TabGroupPlatform.css'

export function TabGroupPlatform({
  cta,
  description,
  headline,
  links,
  media,
}: HeaderTabGroupPlatform) {
  return (
    <div className={s.root}>
      <div className={s.main}>
        <div className={s.headline}>
          <SingleBlockPortableText
            as="p"
            appearance="heading5"
            weight="bold"
            value={headline}
          />
        </div>
        <Link
          {...cta?.payload}
          appearance="secondaryBlue"
          className={s.ctaLink}
        >
          {cta?.payload?.label}
        </Link>
        {isRenderableMediaField(media) && (
          <Media {...media} className={s.mediaMobile} />
        )}
        {isRenderableMediaField(media) && (
          <Media {...media} className={s.mediaDesktop} layout="fill" />
        )}
      </div>
      <div className={s.side}>
        <p className={s.description}>{description}</p>
        <ul className={s.links}>
          {links?.map(({ _key, link }) => (
            <li key={_key}>
              <Link
                {...link?.payload}
                appearance="secondaryBlue"
                className={s.link}
              >
                {link?.payload?.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
