'use client'

import * as s from './DesktopHeaderBackdrop.css'
import { useHeaderContext } from '../../context/HeaderContext'

function DesktopHeaderBackdrop() {
  const {
    headerState: {
      desktop: { activeTabId },
    },
    handleClose,
  } = useHeaderContext()

  const isAnyTabActive = activeTabId !== undefined

  function closeNavigation() {
    if (activeTabId !== undefined) {
      handleClose()
    }
  }

  return (
    <div
      className={s.backdrop({ isVisible: isAnyTabActive })}
      onMouseEnter={closeNavigation}
    ></div>
  )
}

export { DesktopHeaderBackdrop }
