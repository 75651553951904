'use client'
import { Button, ContentContainer, Link } from '@pienso/components'
import { ChevronDown } from '@pienso/icons'
import { HeaderTab, HeaderTabGroup } from '@pienso/models'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useEffect, useRef, useState } from 'react'
import { useHeaderContext } from '../../context/HeaderContext'
import { TabGroupBuilder } from '../TabGroupBuilder/TabGroupBuilder'
import * as s from './DesktopHeaderNavigationItem.css'

function HeaderNavigationItemTab({
  item,
  color = 'auto',
}: {
  item: HeaderTab
  color?: 'auto' | 'dark'
}) {
  const { handleClose } = useHeaderContext()

  const payload = { ...(item.link?.payload ?? {}) }

  if (
    item?.link?.payload?.action?.type === 'linkPayloadActionTooltip' &&
    color === 'dark'
  ) {
    payload.action = {
      ...item.link.payload.action,
      payload: {
        ...item.link.payload.action.payload,
        color: 'grey',
      },
    }
  }

  return (
    <li
      key={item.id}
      className={s.navigationItem({
        disabled: payload.action?.type === 'linkPayloadActionTooltip',
      })}
      onMouseEnter={() => handleClose()}
    >
      <Link
        {...payload}
        appearance={'plainText'}
        className={s.navigationItemLink}
      >
        {payload?.label}
      </Link>
    </li>
  )
}

function HeaderNavigationItemTabGroup({
  item,
  topOffset,
}: {
  item: HeaderTabGroup
  topOffset: number
}) {
  const {
    headerState: {
      desktop: { activeTabId, navigationStatus },
    },
    handleOpen,
    handleSwitch,
    handleClose,
  } = useHeaderContext()
  const tabGroupContentNodeRef = useRef<HTMLDivElement>(null)
  const [triggerNode, setTriggerNode] = useState<HTMLButtonElement | null>(null)

  function activateTabGroup(node: HTMLButtonElement) {
    if (!tabGroupContentNodeRef.current) {
      return
    }

    setTriggerNode(node)

    const { height } = tabGroupContentNodeRef.current.getBoundingClientRect()

    if (navigationStatus === 'EXITED' || navigationStatus === 'EXITING') {
      handleOpen(item.id, height)
    } else {
      handleSwitch(item.id, height)
    }
  }

  function toggleTabGroup(event: React.MouseEvent<HTMLButtonElement>) {
    const triggerNode = event.currentTarget

    if (activeTabId === item.id) {
      setTriggerNode(null)
      handleClose()
      return
    }

    activateTabGroup(triggerNode)
  }

  useCloseTabGroupOnEscapeKey(triggerNode)

  return (
    <li key={item.id} className={s.navigationItem()}>
      <Button
        appearance={'plainText'}
        onClick={toggleTabGroup}
        onMouseEnter={(event) => {
          const triggerNode = event.currentTarget
          activateTabGroup(triggerNode)
        }}
        className={s.navigationItemButton}
        aria-expanded={activeTabId === item.id}
        aria-controls={`tab-group-${item.id}`}
      >
        <span className={s.navigationItemButtonLabel}>{item.title}</span>
        <ChevronDown className={s.groupItemIcon} />
      </Button>

      <div
        className={s.tabGroupContent({
          isOpen: activeTabId === item.id && navigationStatus === 'ENTERED',
        })}
        style={assignInlineVars({
          [s.tabGroupContentTopOffset]: `${topOffset}px`,
        })}
        id={`tab-group-${item.id}`}
        ref={tabGroupContentNodeRef}
        // @ts-expect-error Bad typings within the @types/react. When we use boolean value, it's omitted
        inert={activeTabId !== item.id ? 'true' : undefined}
      >
        <ContentContainer>
          {item.content && <TabGroupBuilder tabGroup={item.content} />}
        </ContentContainer>
      </div>
    </li>
  )
}

export { HeaderNavigationItemTab, HeaderNavigationItemTabGroup }

function useCloseTabGroupOnEscapeKey(node: HTMLElement | null) {
  const {
    headerState: {
      desktop: { activeTabId },
    },
    handleClose,
  } = useHeaderContext()

  const latestHandleClose = useRef(handleClose)

  useEffect(() => {
    latestHandleClose.current = handleClose
  }, [handleClose])

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (node && event.key === 'Escape') {
        latestHandleClose.current()
        node.focus()
      }
    }

    if (!node || activeTabId === undefined) {
      return
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [node, activeTabId])
}
