'use client'

import { theme, useMediaQuery } from '@pienso/styles'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { ReactNode } from 'react'
import * as s from '../Header.css'
import { useHeaderContext } from '../context/HeaderContext'
import { DesktopHeaderBackdrop } from './desktop/DesktopHeaderBackdrop'
import { DesktopHeaderBackground } from './desktop/DesktopHeaderBackground'

interface HeaderWrapperProps {
  children: ReactNode
  hasBackgroundImage: boolean
  color?: string
}

function HeaderWrapper({
  children,
  hasBackgroundImage,
  color,
}: HeaderWrapperProps) {
  const {
    headerState: {
      desktop: { activeTabId, navigationStatus },
      mobile,
    },
  } = useHeaderContext()

  const isLg = useMediaQuery('lg')

  const style = {
    ...assignInlineVars({
      [s.textColor]: hasBackgroundImage
        ? theme.colors.white
        : theme.colors.darkestGray,
      [s.customBackgroundColor]: color,
    }),
  }

  const isAnyTabActive = activeTabId !== undefined

  return (
    <header
      className={s.wrapper({
        isOpen:
          (isLg && isAnyTabActive && navigationStatus !== 'EXITED') ||
          (!isLg && mobile.isMobileMenuOpen),
      })}
      style={style}
    >
      <DesktopHeaderBackground
        topOffset={theme.sizes.desktopHeaderNavigationHeight}
      />
      {children}
      <DesktopHeaderBackdrop />
    </header>
  )
}

export { HeaderWrapper }
