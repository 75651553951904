'use client'

import { HeaderSettings } from '@pienso/models'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useState } from 'react'
import ReactModal from 'react-modal'
import { useHeaderContext } from '../../context/HeaderContext'
import * as s from './MobileHeaderNavigation.css'
import {
  MobileHeaderNavigationItemTab,
  MobileHeaderNavigationItemTabGroup,
} from './MobileHeaderNavigationItem'

interface MobileHeaderNavigationProps {
  navigation: Required<HeaderSettings>['tabs']
  topOffset: number
}

function MobileHeaderNavigation({
  navigation,
  topOffset,
}: MobileHeaderNavigationProps) {
  const {
    headerState: {
      mobile: { isMobileMenuOpen },
    },
    handleCloseMobile,
  } = useHeaderContext()

  const [activeTabIndex, setActiveTabIndex] = useState(-1)

  const appElement = typeof document !== 'undefined' ? document.body : undefined

  const overlayInlineStyles = assignInlineVars({
    [s.modalOverlayTopOffset]: `${topOffset}px`,
  })

  return (
    <ReactModal
      appElement={appElement}
      isOpen={isMobileMenuOpen}
      style={{
        overlay: overlayInlineStyles,
      }}
      overlayClassName={{
        base: s.modalOverlay,
        beforeClose: s.modalContentBeforeClose,
        afterOpen: s.modalContentAfterOpen,
      }}
      shouldCloseOnOverlayClick
      className={{
        base: s.modalContent,
        beforeClose: s.modalContentBeforeClose,
        afterOpen: s.modalContentAfterOpen,
      }}
      onRequestClose={() => {
        handleCloseMobile()
      }}
      closeTimeoutMS={300}
    >
      <nav className={s.navigation}>
        <ul className={s.navigationItems}>
          {navigation.map((tab, index) => {
            if (tab.type === 'tabGroup') {
              return (
                <MobileHeaderNavigationItemTabGroup
                  index={index}
                  key={tab.id}
                  item={tab}
                  isActive={activeTabIndex === index}
                  onPress={() => {
                    if (activeTabIndex === index) {
                      setActiveTabIndex(-1)
                    } else {
                      setActiveTabIndex(index)
                    }
                  }}
                />
              )
            }

            return <MobileHeaderNavigationItemTab item={tab} key={tab.id} />
          })}
        </ul>
      </nav>
    </ReactModal>
  )
}

export { MobileHeaderNavigation }
