import { Link, Media, SingleBlockPortableText } from '@pienso/components'
import { HeaderTabGroupUseCases, isRenderableMediaField } from '@pienso/models'
import * as s from './TabGroupUseCases.css'

export function TabGroupUseCases({
  headline,
  media,
  useCases,
}: HeaderTabGroupUseCases) {
  return (
    <div className={s.root}>
      <div className={s.main}>
        {isRenderableMediaField(media) && (
          <Media
            {...media}
            className={s.mediaDesktop}
            layout="fill"
            sizes="50vw"
          />
        )}
        <div className={s.headline}>
          <SingleBlockPortableText
            as="p"
            appearance="heading5"
            weight="bold"
            value={headline}
          />
        </div>
        {isRenderableMediaField(media) && (
          <Media {...media} className={s.mediaMobile} sizes="100vw" />
        )}
      </div>
      <ul className={s.useCasesGrid}>
        {useCases?.map((useCase) => {
          return (
            <li key={useCase._key}>
              {isRenderableMediaField(useCase.icon) && (
                <div className={s.useCaseIcon}>
                  <Media {...useCase.icon} objectFit="contain" />
                </div>
              )}
              <Link {...useCase.link?.payload} appearance="secondaryBlue">
                {useCase.link?.payload?.label}
              </Link>
              <p className={s.description}>{useCase.description}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
