'use client'

import { ReactNode } from 'react'
import { Link } from '@pienso/components'
import * as s from './LinkHome.css'
import { useHeaderContext } from '../context/HeaderContext'

interface LinkHomeProps {
  children: ReactNode
}

function LinkHome({ children }: LinkHomeProps) {
  const {
    handleClose,
    headerState: {
      desktop: { activeTabId },
    },
  } = useHeaderContext()

  function closeNavigation() {
    if (activeTabId !== undefined) {
      handleClose()
    }
  }

  return (
    <Link
      href="/"
      aria-label="Home"
      className={s.link}
      appearance={'empty'}
      onMouseEnter={closeNavigation}
    >
      {children}
    </Link>
  )
}

export { LinkHome }
