import { Link, Media, SingleBlockPortableText } from '@pienso/components'
import { HeaderTabGroupCompany, isRenderableMediaField } from '@pienso/models'
import * as s from './TabGroupCompany.css'

export function TabGroupCompany({
  headline,
  links,
  medias,
}: HeaderTabGroupCompany) {
  return (
    <div className={s.root}>
      <div>
        <div className={s.headline}>
          <SingleBlockPortableText
            value={headline}
            as="p"
            appearance="heading5"
            weight="bold"
          />
        </div>
        <ul className={s.links}>
          {links?.map(({ link, _key }) => {
            return (
              <li key={_key}>
                <Link
                  {...link?.payload}
                  appearance="secondaryBlue"
                  className={s.link}
                >
                  {link?.payload?.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <div className={s.images}>
        {isRenderableMediaField(medias[0]?.media) ? (
          <Media
            {...medias[0].media}
            className={s.image}
            key={medias[0]._key}
          />
        ) : null}
        {isRenderableMediaField(medias?.[1].media) ? (
          <Media
            {...medias[1].media}
            className={s.image}
            key={medias[1]._key}
          />
        ) : null}
      </div>
    </div>
  )
}
