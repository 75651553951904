'use client'

import { useStickyContent } from '@pienso/sticky-content-provider'
import { sizes, useMediaQuery } from '@pienso/styles'
import throttle from 'lodash.throttle'
import { useEffect, useState, type ReactNode } from 'react'
import * as s from './StickyContent.css'

interface StickyContentProps {
  children: ReactNode
}

function StickyContent({ children }: StickyContentProps) {
  const [state, setState] = useState<'hidden' | 'visible' | 'maybe-visible'>(
    'hidden',
  )

  const isMdBreakpoint = useMediaQuery('md')
  const { isDisabled } = useStickyContent()

  useEffect(() => {
    if (isDisabled) {
      setState('hidden')
      return
    }

    let previousScrollYOffset = window.scrollY

    const throttledEventListener = throttle(
      function showStickyContentWhenScrollingToTheTop() {
        const thresholdScrollYOffset = fromPx(
          isMdBreakpoint ? sizes.desktopHeaderHeight : sizes.mobileHeaderHeight,
        )

        const currentScrollYOffset = window.scrollY
        const currentViewportHeight = window.innerHeight

        if (state === 'hidden') {
          // If we start to scroll up and the static header is not visible, we maybe will show the sticky content
          if (
            currentScrollYOffset < previousScrollYOffset &&
            currentScrollYOffset > thresholdScrollYOffset
          ) {
            setState('maybe-visible')
          }

          previousScrollYOffset = window.scrollY
        }

        if (state === 'maybe-visible') {
          // If we scroll up more than 10% of the viewport height, we show the sticky content
          // unless the static header is visible
          if (
            previousScrollYOffset - currentScrollYOffset >=
              0.1 * currentViewportHeight &&
            currentScrollYOffset > thresholdScrollYOffset
          ) {
            setState('visible')
            previousScrollYOffset = window.scrollY
            return
          }

          // If during the maybe-visible state we start to scroll down, we go back into hidden state
          if (currentScrollYOffset > previousScrollYOffset) {
            setState('hidden')
          }
        }

        if (state === 'visible') {
          // If we scroll down or we hit the bottom of the static header, we hide the sticky content
          if (
            currentScrollYOffset > previousScrollYOffset ||
            currentScrollYOffset <= thresholdScrollYOffset
          ) {
            setState('hidden')
          }

          previousScrollYOffset = window.scrollY
        }
      },
      100,
    )

    window.document.addEventListener('scroll', throttledEventListener)

    return () => {
      throttledEventListener.cancel()
      window.document.removeEventListener('scroll', throttledEventListener)
    }
  }, [isMdBreakpoint, isDisabled, state])

  return (
    <div className={s.wrapper({ isVisible: state === 'visible' })}>
      {children}
    </div>
  )
}

export { StickyContent }

function fromPx(value: string) {
  return Number(value.replace('px', ''))
}
