import { ImageCrop, ImageHotspot } from '@sanity/types'
import { ShopifyResolvedMediaItemPayload } from '@pienso/shopify-sdk'

export type SanityImageType = {
  _type: 'image'
  asset: {
    _ref: string
    _type: 'reference'
  }
  width: number
  height: number
  crop?: ImageCrop
  hotspot?: ImageHotspot
  alt?: string
  unoptimized?: boolean
}
export type MuxVideoType = {
  width: number
  height: number
  aspectRatio: string
  duration: number
  firstFrame: string
  hls: string
  id: string
  mp4: string
  alt?: string
  hasAudioTrack?: boolean
}

export type MuxImageType = {
  src: string
  alt?: string
}

export type SanityImageMediaPayload = {
  type: 'sanity-image'
  image: SanityImageType
}

export type MuxVideoMediaPayload = {
  type: 'mux-video'
  video: MuxVideoType
}

export type MuxImageMediaPayload = {
  type: 'mux-image'
  image: MuxImageType
}

export interface SplineSceneMediaPayload {
  type: 'spline-scene'
  scene: {
    url: string
    placeholder?: ImageMediaOnlyField
  }
}

export type MediaPayload =
  | SanityImageMediaPayload
  | MuxVideoMediaPayload
  | MuxImageMediaPayload
  | ShopifyResolvedMediaItemPayload
  | SplineSceneMediaPayload

export type MediaFieldType = {
  mediaPayloadMobile?: MediaPayload
  mediaPayload?: MediaPayload
}

export type ImageMediaOnlyField = {
  [key in keyof MediaFieldType]: Extract<
    MediaFieldType[key],
    SanityImageMediaPayload
  >
}

type RenderableMediaField = {
  mediaPayload: MediaPayload
  mediaPayloadMobile?: MediaPayload
}

export function isRenderableMediaField(
  media?: MediaFieldType,
): media is RenderableMediaField {
  return Boolean(media?.mediaPayload)
}
