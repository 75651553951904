'use client'

import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useHeaderContext } from '../../context/HeaderContext'
import * as s from './DesktopHeaderBackground.css'

interface DesktopHeaderBackgroundProps {
  topOffset: string
  disableBackgroundColorTransition?: boolean
}

function DesktopHeaderBackground({
  topOffset,
  disableBackgroundColorTransition,
}: DesktopHeaderBackgroundProps) {
  const {
    headerState: { desktop },
  } = useHeaderContext()

  const backgroundStyle = assignInlineVars({
    [s.backgroundHeight]: `${desktop.flyoutHeight}px`,
    [s.backgroundTopOffset]: topOffset,
  })

  return (
    <div
      className={s.background({
        state: desktop.navigationStatus,
        isVisible:
          (desktop.activeTabId !== undefined &&
            (desktop.navigationStatus === 'ENTERING' ||
              desktop.navigationStatus === 'ENTERED' ||
              desktop.navigationStatus === 'SWITCHING')) ||
          !!disableBackgroundColorTransition,
      })}
      style={backgroundStyle}
    />
  )
}

export { DesktopHeaderBackground }
