import { Media } from '@pienso/components'
import { MediaFieldType } from '@pienso/models'
import Link from 'next/link'
import * as s from './BlogArticleCardItem.css'
import { PinIcon } from './PinIcon'
import { isoDateToDate } from '@pienso/utils'

interface ArticleItem {
  title?: string
  author?: string
  path?: string
  datePublished?: string
  cover?: MediaFieldType
}

function BlogArticleCardItem({
  article,
  isFeatured,
}: {
  article: ArticleItem
  isFeatured?: boolean
}) {
  const datePublishedFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  return (
    <li key={article.path} className={s.root}>
      <div className={s.content}>
        {article.title && <h3 className={s.title}>{article.title}</h3>}
        <div className={s.auxiliaryContent}>
          {isFeatured && <p className={s.featuredTag}>Featured</p>}
          {article.datePublished && !isFeatured && (
            <time dateTime={article.datePublished} className={s.datePublished}>
              {datePublishedFormatter.format(
                isoDateToDate(article.datePublished),
              )}
            </time>
          )}
          {article.author && <p className={s.author}>{article.author}</p>}
          {isFeatured && <PinIcon className={s.featuredIcon} />}
        </div>
      </div>
      {article.cover?.mediaPayload !== undefined && (
        <div className={s.cover}>
          <Media
            className={s.media}
            mediaPayload={article.cover.mediaPayload}
            layout="fill"
            sizes="(max-width: 767px) 100vw, (max-width: 999px) 50vw, 25vw"
          />
        </div>
      )}
      <Link href={article.path ?? ''} className={s.link}>
        {article.title}
      </Link>
    </li>
  )
}

export { BlogArticleCardItem }
