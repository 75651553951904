'use client'

import { usePathname } from 'next/navigation'
import { useEffect, useRef } from 'react'

function usePathnameListener(callback: (pathname: string) => void) {
  const pathname = usePathname()
  const latestCallbackRef = useRef(callback)

  useEffect(() => {
    latestCallbackRef.current = callback
  })

  useEffect(() => {
    callback(pathname)
  }, [pathname])
}

export { usePathnameListener }
