'use client'

import { Button, ContentContainer, Link } from '@pienso/components'
import { ChevronDown } from '@pienso/icons'
import { HeaderTab, HeaderTabGroup } from '@pienso/models'
import { useEffect, useRef } from 'react'
import { TabGroupBuilder } from '../TabGroupBuilder/TabGroupBuilder'
import * as s from './MobileHeaderNavigationItem.css'

function MobileHeaderNavigationItemTab({ item }: { item: HeaderTab }) {
  const payload = { ...(item.link?.payload ?? {}) }

  if (item?.link?.payload?.action?.type === 'linkPayloadActionTooltip') {
    payload.action = {
      ...item.link.payload.action,
      payload: {
        ...item.link.payload.action.payload,
        color: 'grey',
      },
    }
  }

  return (
    <li
      key={item.id}
      className={s.navigationItem({
        disabled: payload.action?.type === 'linkPayloadActionTooltip',
      })}
    >
      <Link
        {...payload}
        appearance={'plainText'}
        className={s.navigationItemLink}
      >
        {payload?.label}
      </Link>
    </li>
  )
}

function MobileHeaderNavigationItemTabGroup({
  index,
  item,
  isActive,
  onPress,
}: {
  index: number
  item: HeaderTabGroup
  isActive: boolean
  onPress: () => void
}) {
  const rootRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isActive) {
      // When we change the active tab, we could often end up with the new active tab not being in the viewport
      rootRef.current?.scrollIntoView()
    }
  }, [isActive])

  return (
    <li key={item.id} className={s.navigationItem()} ref={rootRef}>
      <Button
        appearance={'plainText'}
        onClick={(event) => {
          event.stopPropagation()
          onPress()
        }}
        className={s.navigationItemButton}
        aria-expanded={isActive}
        aria-controls={`tab-group-${index}`}
      >
        <span className={s.navigationItemButtonLabel}>{item.title}</span>
        <ChevronDown className={s.groupItemIcon} />
      </Button>

      <div
        className={s.tabGroupContent({
          isOpen: isActive,
        })}
        id={`tab-group-${index}`}
      >
        <div style={{ overflow: 'hidden' }}>
          <ContentContainer>
            {item.content && <TabGroupBuilder tabGroup={item.content} />}
          </ContentContainer>
        </div>
      </div>
    </li>
  )
}

export { MobileHeaderNavigationItemTab, MobileHeaderNavigationItemTabGroup }
