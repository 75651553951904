'use client'

import { ReactNode } from 'react'
import { useHeaderContext } from '../../context/HeaderContext'
import { wrapper } from './MobileHeaderContent.css'

function MobileHeaderContentWrapper({ children }: { children: ReactNode }) {
  const {
    headerState: {
      mobile: { isMobileMenuOpen },
    },
  } = useHeaderContext()

  return <div className={wrapper({ isOpen: isMobileMenuOpen })}>{children}</div>
}

export { MobileHeaderContentWrapper }
