export const ChevronDown = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.269775 2.02329L1.32333 0.969727L4.76978 4.41617L8.21622 0.969727L9.26978 2.02329L4.76978 6.52329L0.269775 2.02329Z"
      fill="currentColor"
    />
  </svg>
)
