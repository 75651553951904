'use client'

import {
  useState,
  createContext,
  FC,
  useContext,
  PropsWithChildren,
  useRef,
} from 'react'
import {
  NAV_ENTERING_TIME,
  NAV_EXITING_TIME,
  NAV_DELAY_TIME,
  NAV_SWITCHING_TIME,
  DESKTOP_COLLAPSED_FLYOUT_HEIGHT,
} from '../Header.config'
import { usePathnameListener, useWindowScrollListener } from '@pienso/utils'

type NavigationStatus =
  | 'EXITED'
  | 'ENTERING'
  | 'ENTERED'
  | 'EXITING'
  | 'SWITCHING'

type HeaderState = {
  desktop: {
    activeTabId?: string
    lastSwitchedTabId?: string
    flyoutHeight: number
    navigationStatus: NavigationStatus
  }
  mobile: {
    isMobileMenuOpen: boolean
    activeTabIndex: number
  }
}

export type HeaderContextType = {
  headerState: HeaderState

  // DESKTOP
  handleOpen: (id: string, flyoutHeight: number) => void
  handleSwitch: (id: string, flyoutHeight: number) => void
  handleClose: (skipDelay?: boolean) => void

  // MOBILE
  handleOpenMobile: () => void
  handleCloseMobile: () => void
  handleChangeMobileTab: (index: number) => void
}

const defaultHeaderState: HeaderState = {
  desktop: {
    flyoutHeight: DESKTOP_COLLAPSED_FLYOUT_HEIGHT,
    navigationStatus: 'EXITED',
  },
  mobile: {
    isMobileMenuOpen: false,
    activeTabIndex: -1,
  },
}

const HeaderContext = createContext<HeaderContextType>({
  headerState: defaultHeaderState,

  // DESKTOP
  handleOpen: () => undefined,
  handleSwitch: () => undefined,
  handleClose: () => undefined,

  // MOBILE
  handleOpenMobile: () => undefined,
  handleCloseMobile: () => undefined,
  handleChangeMobileTab: () => undefined,
})

interface HeaderProviderProps extends PropsWithChildren {
  collapsedFlyoutHeight?: number
}

export const HeaderProvider: FC<HeaderProviderProps> = ({
  children,
  collapsedFlyoutHeight = DESKTOP_COLLAPSED_FLYOUT_HEIGHT,
}) => {
  const [headerState, setHeaderState] = useState<HeaderState>(() => ({
    ...defaultHeaderState,
    desktop: {
      ...defaultHeaderState.desktop,
      flyoutHeight: collapsedFlyoutHeight,
    },
  }))

  // Mobile state settings
  const handleOpenMobile = () =>
    setHeaderState((prevState) => ({
      ...prevState,
      mobile: {
        ...prevState.mobile,
        isMobileMenuOpen: true,
      },
    }))

  const handleCloseMobile = () =>
    setHeaderState((prevState) => ({
      ...prevState,
      mobile: {
        ...prevState.mobile,
        isMobileMenuOpen: false,
      },
    }))

  const handleChangeMobileTab = (index: number) => {
    setHeaderState((prevState) => ({
      ...prevState,
      mobile: {
        ...prevState.mobile,
        activeTabIndex: index,
      },
    }))
  }

  // this timer is used to abort current animation while user made other action
  const transitionTimer = useRef<number | null>(null)
  const clearTransitionTimer = () =>
    transitionTimer.current && window.clearTimeout(transitionTimer.current)

  // this timer is to avoid flyout moving when user accidentally move mouse over nav item
  const delayTimer = useRef<number | null>(null)
  const clearDelayTimer = () =>
    delayTimer.current && window.clearTimeout(delayTimer.current)
  const withDelay = (callback: () => void) => {
    clearDelayTimer()
    delayTimer.current = window.setTimeout(callback, NAV_DELAY_TIME)
  }

  const handleClose: HeaderContextType['handleClose'] = (skipDelay) => {
    clearTransitionTimer()

    const _close = () => {
      setHeaderState((prevState) => ({
        ...prevState,
        desktop: {
          navigationStatus: 'EXITING',
          flyoutHeight: collapsedFlyoutHeight,
        },
      }))
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState((prevState) => ({
          ...prevState,
          desktop: {
            flyoutHeight: collapsedFlyoutHeight,
            navigationStatus: 'EXITED',
            activeTabId: undefined,
          },
        }))
      }, NAV_EXITING_TIME)
    }

    if (skipDelay) {
      _close()
      return
    }

    withDelay(_close)
  }

  const handleOpen: HeaderContextType['handleOpen'] = (id, flyoutHeight) => {
    clearTransitionTimer()
    withDelay(() => {
      setHeaderState((prevState) => ({
        ...prevState,
        desktop: {
          flyoutHeight,
          navigationStatus: 'ENTERING',
          activeTabId: id,
        },
      }))
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState((prevState) => ({
          ...prevState,
          desktop: {
            flyoutHeight,
            navigationStatus: 'ENTERED',
            activeTabId: id,
          },
        }))
      }, NAV_ENTERING_TIME)
    })
  }

  const handleSwitch: HeaderContextType['handleSwitch'] = (
    id,
    flyoutHeight,
  ) => {
    if (headerState.desktop.activeTabId === id) {
      return
    }

    clearTransitionTimer()
    withDelay(() => {
      setHeaderState((prevState) => ({
        ...prevState,
        desktop: {
          flyoutHeight,
          navigationStatus: 'SWITCHING',
          lastSwitchedTabId: headerState.desktop.activeTabId,
          activeTabId: id,
        },
      }))
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState((prevState) => ({
          ...prevState,
          desktop: {
            flyoutHeight,
            activeTabId: id,
            navigationStatus: 'ENTERED',
          },
        }))
      }, NAV_SWITCHING_TIME)
    })
  }

  usePathnameListener(() => {
    handleCloseMobile()
  })

  useWindowScrollListener({
    callback: () => {
      handleClose(true)
    },
    isDisabled: headerState.desktop.activeTabId === undefined,
  })

  return (
    <HeaderContext.Provider
      value={{
        headerState,

        // DESKTOP
        handleOpen,
        handleSwitch,
        handleClose,

        // MOBILE
        handleOpenMobile,
        handleCloseMobile,
        handleChangeMobileTab,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): HeaderContextType => {
  return useContext(HeaderContext)
}
