import "../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fheader%2Fsrc%2Flib%2Fcomponents%2FTabGroupBuilder%2FtabGroups%2FTabGroupResources%2FTabGroupResources.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VSyW7CMBC95yvmUgmkGiUsDTIX%2FqQy8UBdvMkxBFrx71WcAE4AVYiTJc%2BbecvMqPrZH8cshd8EgIvSSnaksHGCLxIIL2E7b8hamoqCM1X4ZpbCnrkBIZ%2B5Vt%2B5y%2BywLljGudAbspKm2JLSM%2Bf7QLwDRM37sO1wkZySUaNuHNQp5jZCk5Xx3qg%2Bvojxk1fdFEHkmfCRxg7ntMu5lnioZ9Qv4cJh4YXRFAojd0pHfbMnvX286m0WvElRelL6o0QK2miMGPIuQ7Bft1SC%2By8KWZq%2BRej5A3QnvLuX0NhaKuSCwUAJTa4MqT0Mw%2BBRfJ%2BtTY%2FKSuaRNGGWFBxaZH4wfods7YK%2F20TOyYfabSzYtv17mQ3wdNU2ibU9SxjNmbZzjOPoKJCsV8%2Fb%2BiVroaXQSC6RX1ZUi%2Bg1z9vme2u5wk9%2FWXQzxQ8EAAA%3D%22%7D"
export var faqLink = 'wzvy2a8';
export var faqSection = 'wzvy2a4';
export var faqSectionTitle = 'wzvy2a5 wzvy2a1 pfiybqv pfiybq2';
export var news = 'wzvy2a3';
export var newsSectionTitle = 'wzvy2a2 wzvy2a1 pfiybqv pfiybq2';
export var question = 'wzvy2a7';
export var questions = 'wzvy2a6';
export var root = 'wzvy2a0';
export var sectionTitle = 'wzvy2a1 pfiybqv pfiybq2';