'use client'

import { Button } from '@pienso/components'
import { useHeaderContext } from '../../context/HeaderContext'
import * as s from './MobileMenuButton.css'

function MobileMenuButton() {
  const {
    handleOpenMobile,
    handleCloseMobile,
    headerState: {
      mobile: { isMobileMenuOpen },
    },
  } = useHeaderContext()

  const buttonLabel = isMobileMenuOpen ? 'Close menu' : 'Open menu'

  return (
    <Button
      appearance="empty"
      className={s.mobileMenuButton}
      onClick={isMobileMenuOpen ? handleCloseMobile : handleOpenMobile}
      aria-label={buttonLabel}
    >
      <svg
        width="32"
        height="40"
        viewBox="0 0 32 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1"
          y1="10.2002"
          x2="31"
          y2="10.2002"
          stroke="currentColor"
          strokeWidth="4"
          className={s.animatedHamburgerIconPath1({ isOpen: isMobileMenuOpen })}
        />
        <line
          x1="1"
          y1="19.8003"
          x2="31"
          y2="19.8003"
          stroke="currentColor"
          strokeWidth="4"
          className={s.animatedHamburgerIconPath2({ isOpen: isMobileMenuOpen })}
        />
        <line
          x1="1"
          y1="29.3999"
          x2="31"
          y2="29.3999"
          stroke="currentColor"
          strokeWidth="4"
          className={s.animatedHamburgerIconPath3({ isOpen: isMobileMenuOpen })}
        />
      </svg>
    </Button>
  )
}

export { MobileMenuButton }
