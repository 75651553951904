import { HeaderTabGroup } from '@pienso/models'
import { TabGroupCompany } from './tabGroups/TabGroupCompany/TabGroupCompany'
import { TabGroupPlatform } from './tabGroups/TabGroupPlatform/TabGroupPlatform'
import { TabGroupResources } from './tabGroups/TabGroupResources/TabGroupResources'
import { TabGroupUseCases } from './tabGroups/TabGroupUseCases/TabGroupUseCases'

interface TabGroupBuilderProps {
  tabGroup: NonNullable<HeaderTabGroup['content']>
}

export const TabGroupBuilder = ({ tabGroup }: TabGroupBuilderProps) => {
  switch (tabGroup._type) {
    case 'tabGroupPlatform':
      return <TabGroupPlatform {...tabGroup} />
    case 'tabGroupUseCases':
      return <TabGroupUseCases {...tabGroup} />
    case 'tabGroupResources':
      return <TabGroupResources {...tabGroup} />
    case 'tabGroupCompany':
      return <TabGroupCompany {...tabGroup} />
    default:
      return null
  }
}
